import { postBodyRequest, exportRequest } from '@/utils/axios'
// 优惠券历史分页
export function page (params) {
  return postBodyRequest('/market/applet/coupon-record/page', params)
}
//  优惠券添加
export function add (params) {
  return postBodyRequest('/market/applet/coupon-record/add', params)
}
//  优惠券删除
export function deleteId (id) {
  return postBodyRequest(`/market/applet/coupon-record/delete/${id}`)
}
//  编辑优惠券规则
export function edit (params) {
  return postBodyRequest('/market/applet/coupon-record/edit', params)
}
export function couponRecordExport (params, fileName) {
  return exportRequest('/market/applet/coupon-record/export', params, fileName)
}
