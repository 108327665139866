import { postBodyRequest,getRequest } from '@/utils/axios'
// 优惠券分页
export function page (params) {
  return postBodyRequest('/market/applet-back/coupon/page', params)
}
//  优惠券添加
export function add (params) {
  return postBodyRequest('/market/applet-back/coupon/add', params)
}
//  优惠券删除
export function deleteId (id) {
  return postBodyRequest(`/market/applet-back/coupon/delete/${id}`)
}
//  编辑优惠券规则
export function edit (params) {
  return postBodyRequest('/market/applet-back/coupon/edit', params)
}
// 优惠券类型
export function coupunTypeList (params) {
  return getRequest('/market/applet-back/coupon/list', params)
}
// 批量发放待兑换优惠券
export function batchGenerateExchange (params) {
  return postBodyRequest('/market/applet-back/coupon/batch-generate-exchange', params)
}
